<template>

  <div class="paymentTypesApi">
    <a v-for="(payType, index) in paymentTypes" :key="index" href="#"
       style="text-align:center; display: inline-block; width: 45%; margin-right: 10px; margin-bottom: 10px; border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 10px; padding: 10px 5px;"
       @click="initializePayment($event)"
       :data-payment-type="payType.type"
       :data-payment-operator="payType.operator">
      <span class="icon-container"><span v-html="payType.icon"></span></span><br />
      {{ payType.name }}

    </a>
  </div>
  <form id="payment-form">
    <div id="payment-element">
      <!-- Stripe will create form elements here -->
    </div>
    <button type="submit" class="payStripeBtn" @click="handleSubmit">Pay via Stripe</button>
  </form>
</template>
<style>
.icon-container {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.icon-container svg {
  width: 100%;
  height: 100%;
}
</style>
<script setup>
import { ref, watch, onMounted } from 'vue';
import { loadStripe } from '@stripe/stripe-js';
import { API_CONFIG } from '@/config.js';

const token = ref(null);
const stripe = ref(null);
const elements = ref(null);
const userToken = localStorage.getItem('token');

const paymentTypes = ref([]);

const props = defineProps({
  type: Number,
  summ: Number,
  productId: Number,
});

/*const initializePayment = async (event) => {
  const paymentType = event.target.dataset.paymentType;
  const paymentOperator = event.target.dataset.paymentOperator;
  if(paymentOperator === 'stripe') {
    await initializeStripe(paymentType);
  }
}*/


const initializeStripe = async () => {
  try {
    let summa = props.summ.replace(/[^0-9.]+/g, '');
    let productId = props.productId.replace(/[^0-9.]+/g, '');
    const response = await fetch(`${API_CONFIG.baseURL}/api/v1/payment/initiate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
      body: JSON.stringify({
        amount: summa,
        currency: 'EUR',
        productId: productId
      })
    });

    const data = await response.json();
    token.value = data.token; // Use to identify the payment

    stripe.value = await loadStripe(API_CONFIG.STRIPE_PUBLISH_KEY);
    const options = {
      clientSecret: data.client_secret,
    };

    elements.value = stripe.value.elements(options);
    const paymentElement = elements.value.create('payment');
    paymentElement.mount('#payment-element');
  } catch (error) {
    console.error('Error during payment initiation:', error);
  }
};

watch(() => props.productId, async (newVal) => {

  if (newVal > 0) {
    await initializeStripe();
  }
  /*console.log(newVal)
  if (newVal > 0) {
    const response = await fetch(`${API_CONFIG.baseURL}/api/v1/pay_types`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      }
    });
    const data = await response.json();
    paymentTypes.value = data.map(item => ({
      type: item.type,
      operator: item.service,
      name: item.label,
      icon: item.icon,
    }));
    console.log(data);

  }*/
});

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const {error} = await stripe.value.confirmPayment({
      elements: elements.value,
      redirect: 'if_required'
    });

    const endpoint = error ? 'failure' : 'complete';
    const body = error
        ? {token: token.value, code: error.code, description: error.message}
        : {token: token.value};

    await fetch(`${API_CONFIG.baseURL}/api/v1/payment/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
      body: JSON.stringify(body)
    });
  } catch (error) {
    console.error('Error during payment confirmation:', error);
  }
};
</script>
