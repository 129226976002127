<script setup>
import BasicInfo from "@/views/wf/account/components/basicInfo.vue";


import { onMounted, ref } from "vue";
import { useI18n } from 'vue-i18n' ;
import { API_CONFIG } from "@/config.js" ;
import AffiliateProgram from "@/views/wf/account/components/affiliateProgram.vue";
import HaveAPoint from "@/views/wf/account/components/haveAPoint.vue";
import ChangeCity from "@/views/wf/account/components/changeCity.vue";
import NavAccount from "@/views/wf/account/components/nav.vue";
import ProfileAccount from "@/views/wf/account/components/profileAccount.vue";
import  { userData, setNameUser, setSnameUser, setEmailUser, setAvatarUser, setPhoneUser, setCityUser, setIsPartner, setIsOwner } from '@/store/userStore.js';


async function getUserInfo() {
  const requestGetOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  };
  const response = await fetch(`${API_CONFIG.baseURL}/api/v1/me`, requestGetOptions);
  const data = await response.json();
  setNameUser(data.data.name);
  setSnameUser(data.data.sname);
  setEmailUser(data.data.email);
  setPhoneUser(data.data.phone);
  setCityUser(data.data.city);
  setAvatarUser(`${API_CONFIG.baseURL}` + data.data.avatar_path);
  setIsPartner(data.data.is_partner);
  setIsOwner(data.data.is_owner);

}

onMounted(() => {

  getUserInfo(); // вызываем нашу функцию при монтировании компонента

});

</script>
<template>
  <div class="container-fluid" style="
padding-right: var(--bs-gutter-x, 0.2rem) !important;
    padding-left: var(--bs-gutter-x, 0.2rem) !important;
    padding-top: 0.5rem !important;">
    <div class="row">
      <div class="col-lg-12">

        <div id="profile" class="card card-body mt-0">
          <profile-account />
        </div>


        <div class="py-4 d-none d-sm-block">
          <div class="mt-3 row">
            <div id="basic-info" class=" col-12 sm-6 col-md-6 col-xl-3 ">
              <div class="card h-100">
                <basic-info />
              </div>
            </div>
            <div id="basic-info" class="mt-4 col-12 sm-6 col-md-6 col-xl-3 mt-md-0">
              <div class="card h-100">
                <affiliate-program/>
              </div>
            </div>

            <div id="basic-info" class="mt-4 col-12 sm-6 col-md-6 col-xl-3 mt-xl-0">
              <div class="card h-100">
                <have-a-point/>
              </div>
            </div>
            <div id="password" class="mt-4 col-12 sm-6 col-md-6 col-xl-3  mt-xl-0">
              <div class="card h-100">
                <change-city/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 d-block d-sm-none">
    <div class="mt-3">
    <nav-account :email="userData.email" />
    </div>
  </div>
</template>
<style scoped>
  .py-4 {
    padding-top: 0.1rem !important;
  }
</style>