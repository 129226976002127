<script setup>

import {ref, onMounted} from 'vue';
import { API_CONFIG } from "@/config.js";
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const orders = ref([]);
onMounted(async () => {
  let token = localStorage.getItem('token');
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
  }
  let response = await fetch(`${API_CONFIG.baseURL}/api/v1/orders`, requestOptions)
  let data = await response.json()
  orders.value = data.data
})
</script>
<template>
  <div className="container-fluid py-5">
    <div className="row">
      <div className="col-12">
        <div className="multisteps-form">
          <div className="row">
            <div className="col-12 col-lg-12 mx-auto mb-12">
              <div className="card">
                <div className="card-body">


                  <div class="row list-layout" style="margin-top: 4px;
" v-for="order in orders" :key="order.id">
                    <h4>Заказ {{ order.id }}</h4>
                    <h4>Товар {{ order.product.name }}</h4>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
