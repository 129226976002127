/**
=========================================================
* Vue Argon Dashboard 2 PRO - v4.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp, onMounted, h } from "vue";
import { createI18n } from 'vue-i18n'
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ru from "./locale/ru.json";
import en from "./locale/en.json";
import pt from "./locale/pt.json";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'


const messages = {
    pt,
    ru,
    en
}

var language = window.navigator.userLanguage || window.navigator.language;
var shortLang = language.substr(0, 2);
if (localStorage.getItem('language')) {
    shortLang = localStorage.getItem('language');
}
const i18n = createI18n({
    legacy: false,
    locale: shortLang,
    fallbackLocale: 'en',
    messages,
})

const appInstance = createApp({
    setup() {
        onMounted(() => {
            // Добавление cordova.js
            const cordovaScript = document.createElement("script");
            cordovaScript.setAttribute("type", "text/javascript");
            cordovaScript.setAttribute("src", "cordova.js");
            document.body.appendChild(cordovaScript);

            // Ожидание события deviceready от Cordova
            document.addEventListener("deviceready", () => {
                window.handleOpenURL = function (url) {
                    console.log("Получен URL:", url);

                    // Проверяем, что это редирект от Google
                    if (url.startsWith("wofooo.com://auth/token")) {
                        // Извлекаем токен из URL
                        let params = new URLSearchParams(url.split("?")[1]);
                        let accessToken = params.get("token");

                        if(accessToken) {
                            localStorage.setItem('token', accessToken);
                            router.push({ name: 'AuthLocation'});
                        } else {
                            // Обработка ситуации, когда token отсутствует в строке запроса
                            alert('Ошибка установки токена, повторите позже');
                        }
                        // Сохраните токен для дальнейшего использования
                    }
                };
            });
        });
    },
    render: () => h(App),
});
appInstance.component('v-select', vSelect)
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.use(i18n);
appInstance.mount("#app");

