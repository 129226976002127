<script setup>
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";

import { useI18n } from 'vue-i18n'
import {  setAuthType } from '@/store/tempUserAuthStore.js'


import LangBlock from "@/examples/components/Lang.vue";


const { t } = useI18n() // 't' это функция для выполнения перевода
import { API_CONFIG } from "@/config.js";
import { useRouter } from "vue-router";
const router = useRouter();

import ArgonInput from "@/components/ArgonInput.vue";
import RightLayout from "@/views/wf/auth/components/RightLayout.vue";
const body = document.getElementsByTagName("body")[0];

const store = useStore();
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});

const errorMessage = ref(null)
const errors = ref(null)

const sendCode = async (device_name) => {
  let emailElement = document.getElementById('email');
  if (!emailElement) {
    throw new Error('No email input found');
  }

  const email = emailElement.value;
  localStorage.setItem('tempEmail', email);
  let data = { device_name, email };

  await fetch(`${API_CONFIG.baseURL}/api/v1/send_code`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  }).then(async response => {
    const data = await response.json()
    if (data.success) {
      router.push({ name: 'AuthCode'})
    }
    if (data.code === 1) {
      errors.value = data.errors
    } else {
      errorMessage.value = data
      console.error('There was an error!', data)
    }
  })
   .catch(error => {
     console.log( error)
    if (error.code === 1) {
      errors.value = error.errors
    } else {
      errorMessage.value = error
      console.error('There was an error!', error)
    }

  });


};

const aType = ref('email');
const authType = (type) => {
  aType.value = type ;
};
const authWithType = () => {
  setAuthType(aType.value);
  if (aType.value === 'email') {
    // send code and redirect to set city then code

      sendCode(navigator.userAgent);

  }

  if (aType.value === 'google') {
    //window.location.href = `${API_CONFIG.baseURL}/google/redirect`;
    let authUrl = `${API_CONFIG.baseURL}/google/redirect`;
    cordova.plugins.browsertab.openUrl(authUrl);
  }
  if (aType.value === 'apple') {
    let authUrl = `${API_CONFIG.baseURL}/apple/redirect`;
    cordova.plugins.browsertab.openUrl(authUrl);
  }
  if (aType.value === 'facebook') {
    let authUrl = `${API_CONFIG.baseURL}/facebook/redirect`;
    cordova.plugins.browsertab.openUrl(authUrl);
  }
};

</script>
<style scoped>
.login-img {
  height: 35px;
}
.login-a {
  margin-left: 21px;
  margin-right: 21px;
  cursor: pointer;
}
.justify-content-lg-end {
  justify-content: center !important;
}
</style>
<template>
  <main class="mt-0 main-content">
    <div class="page-header min-vh-100">
      <div class="container">
        <div class="row">
          <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 text-center justify-content-center position-relative flex-column my-auto d-lg-flex"
          >
            <div class="mx-auto text-center" style="width: 200px">
              <img style="border: 0px !important;" src="@/assets/img/logo-ct-dark.svg" class="mb-3" :style="{ backgroundSize: 'cover', height: '150px', width: '150px' }" />
            </div>
            <div class="card card-plain text-center">
              <div class="pb-0 card-header text-start text-center">
                <h4 class="font-weight-bolder">{{ t('auth.letsStart')}}</h4>
                <p class="mb-0 text-center">
                  {{ t('auth.way') }}
                </p>
              </div>
              <div class="card-body">
                  <div class="row">
                  <div class="btn-wrapper text-center mb-4">
                    <a
                        @click="authType('google')"
                        class="login-a"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                    >
                      <img
                          class="login-img"
                          src="@/assets/img/logos/google.svg"
                      />

                    </a>
                    <a
                        class="login-a"
                        @click="authType('apple')"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                    >
                      <img
                          class="login-img"
                          src="@/assets/img/logos/apple.svg"
                      />

                    </a>
                    <a
                        @click="authType('facebook')"
                        class="login-a"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                    >
                      <img
                          class="login-img"
                          src="@/assets/img/logos/facebook.svg"
                      />

                    </a>
                  </div>
                    <p class="mb-0 text-center">
                      {{ t('auth.or')}}
                    </p>
                  </div>
                  <div class="mt-4">
                    <argon-input
                        id="email"
                        v-model="email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        size="lg"
                    />
                    <span class="text-danger" v-if="errors?.email">{{ errors.email[0] }}</span>
                  </div>
                  <button type="button" class="btn w-100 bg-gradient-success" data-bs-toggle="modal" @click="authType('email')" data-bs-target="#exampleModal">
                    {{ t('auth.continue')}}
                  </button>

              </div>
            </div>

            <div>
              <lang-block></lang-block>
            </div>
          </div>




            <right-layout></right-layout>

        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable  modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Manage Your Cookie Preferences</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <h6>Essential Cookies</h6>
            <p>These cookies are necessary for the proper functioning of our website and cannot be turned off.</p>
            <h6>Marketing Cookies</h6>
            <p>These cookies help us to deliver personalized content and track the performance of our marketing campaigns. You can choose to disable these cookies if you prefer.</p>
            <h6>Cookie Policy</h6>
            <p>Our Cookie Policy is <a href="/policy/cookie" target="_blank">here</a>.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn bg-gradient-success w-100" data-bs-dismiss="modal" @click="authWithType">Accept All</button>
            <button type="button" class="btn bg-outline-success w-100" data-bs-dismiss="modal" @click="authWithType">Accept Essential Only</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
